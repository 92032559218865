var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveTeam.apply(null, arguments)}}},[_c('v-container',[_c('p',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v("Equipe de Captação:")]),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"color":"#086318","label":"Nome do Profissional","outlined":"","dense":""},model:{value:(_vm.professional.name),callback:function ($$v) {_vm.$set(_vm.professional, "name", $$v)},expression:"professional.name"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"color":"#086318","label":"Nº do registro","outlined":"","dense":""},model:{value:(_vm.professional.register),callback:function ($$v) {_vm.$set(_vm.professional, "register", $$v)},expression:"professional.register"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('v-tooltip',{attrs:{"right":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"green","type":"submit"},on:{"click":function($event){return _vm.addTeam()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-multiple-plus ")])],1)]}}])},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Adicionar profissional")])])],1)],1),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.team,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"20","color":"red","align-item":"center"},on:{"click":function($event){return _vm.deleteTeam(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Excluir")])])]}}])})]],2)],1),_c('v-container',[_c('div',{staticClass:"buttons"},[_c('v-row',[(_vm.enableButton == true)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"0","color":"green"},on:{"click":function($event){return _vm.$router.push('/captacao')}}},[_c('span',{staticClass:"textBtn"},[_vm._v("Concluir")])]):_vm._e(),_c('p',{staticClass:"space-personalized"}),_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"0","color":"grey"},on:{"click":function($event){return _vm.$router.push('/captacao')}}},[_c('span',{staticClass:"textBtn"},[_vm._v("Sair")])]),_c('p',{staticClass:"space-personalized"}),_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"0","color":"red"},on:{"click":function($event){return _vm.distribution()}}},[_c('span',{staticClass:"textBtn"},[_vm._v("Distribuição/Disponibilização")])])],1)],1)]),_c('br'),_c('v-snackbar',{attrs:{"absolute":"","background-color":"white","color":"#104a21"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }