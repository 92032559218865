<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <v-tabs fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab>EDITAR CAPTAÇÃO</v-tab>
            <v-tab>EDITAR REMOÇÃO DE ORGÃOS</v-tab>
            <v-tab>EDITAR EQUIPE DE CAPTAÇÃO</v-tab>
 
            <v-tab-item>
              <v-container>
                <h4>Doador: {{ this.donorName }}</h4>  
                <br>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="1">
                      <v-text-field 
                          color="#086318" 
                          label="Horário"
                          v-mask="'##:##'"
                          outlined
                          dense
                          v-model="organCaptured.hour">
                      </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field 
                          color="#086318" 
                          label="Início da cirurgia"
                          v-mask="'##:##'"
                          outlined
                          dense
                          v-model="organCaptured.start_surgery">
                      </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field 
                          color="#086318" 
                          label="Clampeamento de aorta"
                          outlined
                          dense
                          v-model="organCaptured.aortic_clamping">
                      </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field 
                          color="#086318" 
                          label="Tempo de perfusão"
                          outlined
                          dense
                          v-model="organCaptured.perfusion_time">
                      </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field 
                          color="#086318" 
                          label="Término da cirurgia"
                          v-mask="'##:##'"
                          outlined
                          dense
                          v-model="organCaptured.end_surgery">
                      </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="3">
                      <v-text-field 
                          color="#086318" 
                          label="Motivo da não captação"
                          outlined
                          dense
                          v-model="organCaptured.reason_for_not_takingup">
                      </v-text-field>
                  </v-col>
              </v-row>
              
              <v-row>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-textarea 
                  color="#086318" 
                  label="Descrição cirúrgica"
                  row-height="10"
                  rows="15"
                  outlined
                  dense
                  v-model="organCaptured.surgery_description">
                  </v-textarea>
                </v-col>
              </v-row>
              
              <v-row>
                <v-btn @click="updated()" class="rounded-lg" elevation="0" color="green">
                    <span class="textBtn">Atualizar</span>
                </v-btn> 
                <p class="space-personalized"></p>
                <v-btn @click="$router.push('/captacao')" class="rounded-lg" elevation="0" color="grey">
                    <span class="textBtn">Sair</span>
                </v-btn> 
              </v-row>
            </v-container>
           </v-tab-item>

           <v-tab-item>
            <editar-orgons-captured />
           </v-tab-item>

           <v-tab-item>
            <EditarEquipeCaptacao />
           </v-tab-item>
      </v-tabs>
  </v-card>
  <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Fechar
          </v-btn>
      </template>
  </v-snackbar>
  </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import EditarOrgonsCaptured from "./EditarOrgonsCaptured-comp.vue";
  import EditarEquipeCaptacao from "./EditarEquipeCaptacao-comp.vue";

  export default {
    data() {
      return {
        dialog: false,
        headers: [
          {
            text: "Orgão Captado",
            align: "center",
            value: "orgon_name",
          },

          { text: "Ações", value: "actions", sortable: false,  align: "center" },
        ],

        displayError: {
          display: false,
          message: [],
        },
        tab: 2,
        page: 1,
        totalUsers: 0,
        numberOfPages: 0,
        loading: true,
        options: {},
        sortBy: "name",
        organCaptured: [],
        donorCaptations: [],
        donorEdit: [],
        message: "",
        name: "",
        itemID: "",
        captationId: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
      };
    },
    
    components: {
      EditarOrgonsCaptured,
      EditarEquipeCaptacao
    },

    created() {
      const donor = this.$route.query.item;
      this.donorName = donor.name
        this.donor_id = donor.id;
            axios.get(`/captation/allByDonors/${this.donor_id}`).then((response) => {
                console.log('response', response)
                this.loading = false;
                this.donorEdit = response.data.data[0]
                this.captationId = this.donorEdit.id
                this.organCaptured = this.donorEdit
            });
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
     
      deep: true,
    },
  
    methods: {
      
      show(item){
          this.organCaptured = item
          this.itemID = item.id
      },

      updated(){
        const captation = this.organCaptured
          axios
          .put(`/captation/${this.captationId}`, captation)
          .then((response) => {
            this.message = response.data.message
            this.snackbar = true;
            this.dialog = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      },

    },
    
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 5px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon{

  }

  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  