<template>
    <div>
        <v-form @submit.prevent="addOrgon" ref="form">
            <v-container>
                <p style="font-weight:bold;font-size:20px;">Orgãos Captados:</p>
                <v-row>
                    <v-col class="d-flex" cols="12" sm="3">
                        <v-select 
                            color="#086318" 
                            v-model="organCaptured.orgon_id" 
                            :items="orgons"
                            :disabled="disableCaptationForm"
                            item-text="name" 
                            item-value="id" 
                            dense 
                            outlined
                            label="Orgão">
                        </v-select>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="5">
                        <v-text-field 
                        color="#086318" 
                        label="Centro Receptor"
                        :disabled="disableCaptationForm"
                        outlined
                        dense
                        v-model="organCaptured.receiver_center">
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="4">
                        <v-select 
                            color="#086318" 
                            v-model="organCaptured.packed" 
                            :items="yesorNot"
                            item-text="name" 
                            :disabled="disableCaptationForm"
                            item-value="id" 
                            dense 
                            outlined
                            label="Orgão devidamente acondicionado?">
                    </v-select>
                </v-col>
                <!-- <v-col class="d-flex" cols="12" sm="2" v-if="this.lung == true">
                    <v-text-field 
                        color="#086318" 
                        label="Tronco Pulmonar"
                        :disabled="disableCaptationForm"
                        outlined
                        dense
                        v-model="organCaptured.lung_trunk">
                    </v-text-field>
                </v-col> -->
            </v-row>

            <v-row>
                <v-col class="d-flex" cols="12" sm="4">
                    <v-select 
                        color="#086318" 
                        v-model="organCaptured.preservation_solutions_id"
                        :items="solucoes"
                        :disabled="disableCaptationForm"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined
                        label="Solução de Preservação">
                    </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-text-field 
                    color="#086318" 
                    label="Quantitativo em ml"
                    outlined
                    dense
                    :disabled="disableCaptationForm"
                    v-model="organCaptured.quantity">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-text-field 
                    color="#086318" 
                    label="Lote"
                    outlined
                    dense
                    :disabled="disableCaptationForm"
                    v-model="organCaptured.lot">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-tooltip right color="green">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-1"  small fab dark color="green" v-bind="attrs" v-on="on" @click="addOrgon()">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                            </v-btn>
                        </template>
                        <span style="color:white">Adicionar orgão</span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <!-- <p style="font-weight:bold;font-size:20px;" v-if="this.enableNephrectomyForm == true"> Nefréctomia:</p> -->
            <v-row v-if="this.enableNephrectomyForm == true">
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="nephrectomyForm.normal_morphology">
                        <p>Morfologia Normal?</p>
                            <v-radio 
                            label="Sim"  
                            value=1
                            color="#086318"></v-radio>

                            <v-radio label="Não"  
                            value=2
                            color="#086318"></v-radio>
                        </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="nephrectomyForm.single_arterial_pedicle">
                        <p>Pedículo Arterial Único?</p>
                            <v-radio 
                            label="Sim"  
                            value=1
                            color="#086318"></v-radio>

                            <v-radio label="Não"  
                            value=2
                            color="#086318"></v-radio>
                        </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="nephrectomyForm.single_venous_pedicle">
                        <p>Pedículo Venoso Único?</p>
                            <v-radio 
                            label="Sim"  
                            value=1
                            color="#086318"></v-radio>

                            <v-radio label="Não"  
                            value=2
                            color="#086318"></v-radio>
                        </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules"  v-model="nephrectomyForm.single_ureteral_rout">
                        <p>Via Ureteral Única?</p>
                            <v-radio 
                            label="Sim"  
                            value=1
                            color="#086318"></v-radio>

                            <v-radio label="Não"  
                            value=2
                            color="#086318"></v-radio>
                        </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="nephrectomyForm.adequate_perfusion">
                        <p>Perfusão Adequada?</p>
                            <v-radio 
                            label="Sim"  
                            value=1
                            color="#086318"></v-radio>

                            <v-radio label="Não"  
                            value=2
                            color="#086318"></v-radio>
                        </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-tooltip right color="green">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="saveNephrectomy()">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    <span style="color:white">Adicionar Nefréctomia</span>
                </v-tooltip>
                </v-col>
            </v-row>
                <template>
                        <v-data-table
                            :headers="headers"
                            :items="orgonsCapted"
                            :items-per-page="10"
                            class="elevation-1"
                        >
                            <template v-slot:item.actions="{ item }" :resource="item">
                                <v-tooltip right color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                            size="20"
                                            class="icon"
                                            color="red" 
                                            v-on="on"
                                            v-bind="attrs"
                                            align-item="center"
                                            @click="deleteTeam(item)">
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </template>
                                    <span style="color:white">Excluir</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </template>
            </v-container>
        </v-form>
        <v-container>
            <div class="buttons">
                <v-row>
                    <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/captacao')">
                        <span class="textBtn">Sair</span> 
                    </v-btn>
                </v-row>
            </div>
        </v-container>
        <br>
        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from "@/axios";
    import EventBus from '@/utils/eventBus'
    export default {
        data() {
            return {
                headers: [
                {
                    text: "Orgão",
                    align: "center",
                    value: "orgon_name",
                },
                {
                    text: "Centro Receptor ",
                    align: "center",
                    value: "receiver_center",
                },
                {
                    text: "Solução de Preservação",
                    align: "center",
                    value: "preservation_solutions_name",
                },
                {
                    text: "Quantitativo",
                    align: "center",
                    value: "quantity",
                },
                { text: "Ações", value: "actions", sortable: false,  align: "center" },
                ],
                dialog: false,
                displayError: {
                    display: false,
                    message: [],
                },
                tab: 2,
                loading: true,
                enableButton: false,
                options: {},
                orgonsCapted: [],
                orgons: [],
                solucoes: [],
                message: "",
                lung: false,
                enableNephrectomyForm: false,
                disableCaptationForm: false,
                organCaptured: {
                    captation_id: this.$route.query.item.captation.id,
                    donor_id: this.$route.query.item.id,
                },
                nephrectomyForm: {
                    orgons_captured_id: ""
                },
                yesorNot: [
                    {
                        name: 'Sim',
                        value: '1'
                    },
                    {
                        name: 'Não',
                        value: '2'
                    }
                ],
                snackbar: false,
                messageError: "",
                formRules: [
                    (v) => !!v || "Este campo é obrigatório",
                ],
                professional: {
                    donor_id: localStorage.getItem('donorID'),
                }
            };
        },

        components:{
        },

        created() {
            this.indoToTable();
        },

        computed: {
            orgon_id() {
                return this.organCaptured.orgon_id;
            }
        },
        
        watch: {
            orgon_id(e){
                if(e == 5 || e == 6){
                    this.teste = true
                    this.lung = true
                    this.isCornea = false
                    this.isKidney = false
                    this.organCaptured.receiver_center = ""
                }else if(e == 3 || e == 4){
                    this.isCornea = false
                    this.isKidney = true
                    this.lung = false
                    this.organCaptured.receiver_center = ""
                }else if(e == 10 || e == 11){
                    this.isCornea = true
                    this.organCaptured.preservation_solutions_id = 4
                    this.cornea = true
                    this.lung = false
                    this.isKidney = false
                    this.organCaptured.receiver_center = "Banco de Olhos da Bahia";
                }else{
                    this.isCornea = false
                    this.isKidney = false
                    this.teste = false
                    this.lung = false
                    this.organCaptured.receiver_center = ""
                }
            },
        },
         
        methods: {
            indoToTable(){
                axios.get(`/orgons_captured/${this.organCaptured.donor_id}`).then((response) => {
                    this.orgonsCapted = response.data.data;
                });

                axios.get("/orgon").then((response) => {
                    this.orgons = response.data.data;
                });

                axios.get("/preservation_solutions").then((response) => {
                    this.solucoes = response.data.data;
                });
            },
        
            addOrgon(){
                const orgonsCaptured = this.organCaptured
                if(this.organCaptures != ""){
                    axios
                    .post(`/orgons_captured`, orgonsCaptured).then((response) => {
                        if(this.organCaptured.orgon_id == 3 || this.organCaptured.orgon_id == 4){
                            this.enableNephrectomyForm = true
                            this.nephrectomyForm.orgons_captured_id = response.data.id.id
                        }
                        this.enableButton = true
                        this.organCaptured.orgon_id = "",
                        this.organCaptured.packed = "",
                        this.organCaptured.lung_trunk = "",
                        this.organCaptured.receiver_center = "",
                        this.organCaptured.preservation_solutions_id = "",
                        this.organCaptured.quantity = "",
                        this.organCaptured.lot = "",
                        this.message = response.data.message
                        this.snackbar = true;
                        this.dialog = false; 
                        this.indoToTable()
                        })
                            .catch((err) => {
                            err;
                            var error = "";
                            Object.values(this.$store.state.errorMessage).map((value) => {
                                error = value;
                            });
                            this.displayError.message = error.toString();
                            this.displayError.display = true;
                            setTimeout(() => {
                                this.displayError.display = false;
                            }, 3000);
                        });
                }
            },

            saveNephrectomy(){
                const nephrectomy = this.nephrectomyForm
                axios
                    .post(`/nephrectomy`, nephrectomy).then((response) => {
                        this.enableNephrectomyForm = false
                        this.message = response.data.message
                        this.snackbar = true;
                        this.dialog = false; 
                        })
                            .catch((err) => {
                            err;
                            var error = "";
                            Object.values(this.$store.state.errorMessage).map((value) => {
                                error = value;
                            });
                            this.displayError.message = error.toString();
                            this.displayError.display = true;
                            setTimeout(() => {
                                this.displayError.display = false;
                            }, 3000);
                        });
            },

            nextTab(){
                this.$emit('changeTab', 3);
            },

            deleteTeam(item){
                axios.delete(`/orgons_captured/${item.id}`).then((response) => {
                    this.loading = true;
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.indoToTable();
                })
            },

            openDialog(){
                EventBus.$emit("teste", 1)
            }

        }
    }
</script>

<style scoped>
.buttons{
    padding-left: 20px;
}
.newDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.text-h5{
    background-color: #50c78f;
    color: white;
    text-shadow: 1px 2px 2px #309171
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}
.space-personalized {
    padding: 4px;
}
.d-flex {
    padding: 0 12px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}

 .cardFooter {
    padding: 0 !important;
}
</style>